(function () {
  'use strict';

  /* @ngdoc object
   * @name home.statistics
   * @description
   *
   */
  angular
    .module('neo.home.statistics', [
      'ui.router',
      'neo.home.statistics.licenses',
      'neo.home.statistics.teams',
      'neo.home.statistics.entities'
    ]);
}());
